<template>
  <van-overlay :show="show" class="overlay">
    <div class="overPopup">
      <div class="popup-title">绑定微信号</div>
      <div class="popup-wx">
        <span>微信号：</span>
        <input class="input" v-model="wechatId" type="text" placeholder="请输入微信号" />
      </div>
      <div class="popup-input">
        <div class="popup-but" style="border-right: 2px solid #f4f4f4;box-sizing: border-box" @click="bindWx">绑定</div>
        <div class="popup-but" @click="show = false">取消</div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { getToken } from '../../../api'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'settingWx',
  watch: {
    show() {
      this.$emit('change-event', this.show)
    },
  },
  data() {
    return {
      show: false,
      wechatId: '',
      wxId: '',
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    getSetting(show, wechatId) {
      this.show = show
      this.wechatId = wechatId
      this.wxId = wechatId
    },
    bindWx() {
      if (this.wechatId === '') {
        this.$toast('请填写微信号')
        return
      }

      if (this.wechatId === this.wxId) {
        this.$toast('已绑定该微信号')
        return
      }

      getToken({
        trxCode: 'XF161',
        wechatId: this.wechatId,
      }).then((res) => {
        if (res.custMsg) {
          this.show = false
          this.$toast('绑定成功')
          this.getUserInfo()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  .overPopup {
    width: 80%;
    height: auto;
    background: #ffffff;
    border-radius: 2vw;
    font-size: 4vw;
    text-align: center;
  }
}
.popup-title {
  padding-top: 3vw;
}

.popup-wx {
  padding: 4vw;
  .input {
    padding: 2vw;
    width: 70%;
    border: none;
    border-bottom: 1px solid #cccccc;
    color: #999999;
  }
}
.popup-input {
  border-top: 2px solid #f6f6f6;
  .popup-but {
    width: 50%;
    color: #7797f3;
    display: inline-block;
    padding: 2vw 0;
  }
}
</style>
