<template>
  <div class="app-contianer">

    <div class="setting-list page-item">
      <div class="setting-text" @click="goPage('/editUserinfo')">
        <div class="setting-left">
          <div class="left-title">{{custMsg.nickName || '用户' + custMsg.custId}}</div>
          <div class="left-id">
            <span>ID:{{custMsg.custId}}</span>
            <img src="../../assets/user/alreadyselect.png" v-if="custMsg.vipPlus === 1" />
            <img src="../../assets/user/notselect.png" v-if="custMsg.vipPlus === 0" />
          </div>
        </div>
        <div class="setting-right">
          <img class="mainBorder2" :src="custMsg.imgUrl" v-if="custMsg.imgUrl" />
          <img class="mainBorder2" :src="defaultUserImg" v-else />
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>

      <div class="setting-other" @click="bindPhone()">
        <div class="other">手机号</div>
        <div class="other other-last">
          <span>{{custMsg.phone ? custMsg.phone:'去绑定'}}</span>
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>
      <div class="setting-other" @click="setting">
        <div class="other">微信号</div>
        <div class="other other-last">
          <span>{{custMsg.wechatId ? custMsg.wechatId:'去绑定'}}</span>
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>
      <div class="setting-other" @click="goPage('/setLogon')">
        <div class="other">支付宝账号</div>
        <div class="other other-last">
          <span>{{custMsg.logonId ? custMsg.logonId:'去绑定'}}</span>
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>
      <div class="setting-other">
        <div class="other">用户等级</div>
        <div class="other other-last">{{custMsg.level | levelFilter}}</div>
      </div>
      <!-- <div v-if="agentConfig.THEME === 'FOUR_SEASONS'" class="setting-other" @click="goPage('/changeTheme')">
        <div class="other">切换主题</div>
        <div class="other other-last">
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div> -->
      <div v-if="isApp" class="setting-other" @click="goPage('/setPassword')">
        <div class="other">登录密码</div>
        <div class="other other-last">
          <span>修改密码</span>
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>
      <div class="setting-other" v-if="custMsg.vipPlus === 1">
        <div class="other">plus会员到期时间</div>
        <div class="other other-last"> {{custMsg.vipPlusExpire}}</div>
      </div>
      <div class="setting-other">
        <div class="other">agentId</div>
        <div class="other other-last">
          <span>{{custMsg.agentId}}</span>
        </div>
      </div>
      <div class="setting-other" style="border-bottom: none" @click="showVconsole()">
        <div class="other">版本号</div>
        <div class="other other-last">
          <span v-if="isApp">{{version}}</span>
          <span v-else>4.3.5</span>
        </div>
      </div>
      <div v-if="isApp" class="setting-other" style="border-bottom: none" @click="goPage('/cancellation')">
        <div class="other">注销账户</div>
        <div class="other other-last">
          <van-icon name="arrow" color="#CCCCCC" size="15px" />
        </div>
      </div>
    </div>

    <div class="phone-button">
      <!-- <div class="button" v-if="versions === 'WeChat'" @click="changeupdate()">更新信息
      </div> -->
      <div class="submitBut" @click="downSing()">退出登录</div>
    </div>
    <!--    修改微信号-->
    <settingWx v-show="show" ref="settingWx" style="position: absolute" @change-event="emitEvent"></settingWx>
  </div>
</template>

<script>
import VConsole from 'vconsole';
import settingWx from './compent/settingWx.vue'
import { mapState } from 'vuex'
import thechangePhone from '@/components/changePhone.js'
const agentCustomize = require('@/utils/agentCustomize')

export default {
  name: 'settings',
  components: {
    settingWx,
  },
  data() {
    return {
      defaultUserImg: agentCustomize.defaultUserImg,
      show: false,
      showzfb: false,
      version: '2.0.0',
      vconsoleNum: 0,
      theSetTimeout: null
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions', 'isApp', 'agentConfig']),
  },
  filters: {
    levelFilter(val) {
      let map = [
        { value: 1, label: '普通用户' },
        { value: 2, label: '团长' }
      ]
      let item = map.find(v => v.value === val)
      return item ? item.label : val
    }
  },
  created() {
    this.version = localStorage.getItem('nativeVersion')

    let ran = navigator.userAgent
    this.isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    this.isiOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    this.isiOSApp = this.isiOS && this.isApp
  },
  methods: {
    // t退出登录
    downSing() {
      localStorage.removeItem('H5token')
      localStorage.removeItem('loginType')
      this.$router.push('/')
    },

    // 更换手机号码
    bindPhone() {
      // 未绑定手机号
      if (this.custMsg.phoneUser === "N") {
        thechangePhone()
      } else {
        this.goPage('/phone')
      }
    },
    goPage(path) {
      this.$router.push(path)
    },
    emitEvent(val) {
      this.show = val
    },
    changeupdate() {
      this.$router.push({ path: '/updateUser' })
    },
    setting() {
      this.show = true
      this.$refs.settingWx.getSetting(this.show, this.custMsg.wechatId)
    },

    // 调试控制台
    showVconsole() {
      // if (!this.isApp) return
      if (this.vconsoleNum >= 6) {
        const vConsole = new VConsole();
      } else {
        this.vconsoleNum++
      }

      if (!this.theSetTimeout) {
        this.theSetTimeout = setTimeout(() => {
          this.vconsoleNum = 0
          clearTimeout(this.theSetTimeout)
          this.theSetTimeout = null
        }, 3000);
      }

    }
  },
}
</script>

<style scoped lang="less">
.setting-text {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 12px;
  border-bottom: 1px solid #f6f6f6;

  .setting-left {
    flex: 1;
    .left-title {
      font-size: 4vw;
      font-weight: bold;
      color: #222;
    }
    .left-id {
      font-size: 3.2vw;
      color: #999999;
      font-weight: 400;
      img {
        width: 14vw;
        margin-left: 2vh;
      }
    }
  }
  .setting-right {
    > * {
      vertical-align: middle;
    }

    img {
      width: 6.5vh;
      height: 6.5vh;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 2vw;
    }
  }
}
.setting-list {
  padding: 16px;
  padding-bottom: 0;
  margin-top: 4vw;
  .setting-other {
    display: flex;
    border-bottom: 1px solid #f6f6f6;
    padding: 5vw 0;
    .other {
      width: 50%;
      font-size: 3.6vw;
      color: #333333;
    }
    .other-last {
      text-align: right;
      color: #999999;
    }
  }
}

.phone-button {
  margin-top: 15vw;
}
</style>
